// For python and js
export const pyJsParser = (filters) => {
  if (!filters) return ''
  const filterStringParsed = Object.keys(filters).reduce((acc, key) => {
    if (!filters[key]) return acc
    acc += `${key}={'${filters[key]}'}&`
    return acc
  }, '')
  const filteredQuery = filterStringParsed.substring(0, filterStringParsed.length - 1)
  return filteredQuery
}

export const curlParser = (filters) => {
  if (!filters) return ''
  const filterStringParsed = Object.keys(filters).reduce((acc, key) => {
    if (!filters[key]) return acc
    acc += `${key}=${filters[key]}&`
    return acc
  }, '')
  const filteredQuery = filterStringParsed.substring(0, filterStringParsed.length - 1)
  return filteredQuery
}

export const cliParser = (filters) => {
  if (!filters) return ''
  const filterStringParsed = Object.keys(filters).reduce((acc, key) => {
    if (!filters[key]) return acc
    acc += `--${key}={${filters[key] || ''}} `
    return acc
  }, '')
  return filterStringParsed
}
