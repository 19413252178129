import SectionWrapperGettingStarted from '../SectionWrapperGettingStarted'
import style from '../style.module.scss'

const StepOne = () => {
  return (
    <SectionWrapperGettingStarted linkName={'step_one'}>
      <div className={style.stepOneContainer}>
        <div className={style.headlineContainer}>
          <h3 className="toclink" data-link="getting_started/step_one">
            1. Request a Secret Key
          </h3>
        </div>
        <div>
          <h4>Request an API secret key from PlanetWatchers.</h4>
        </div>
      </div>
    </SectionWrapperGettingStarted>
  )
}

export default StepOne
