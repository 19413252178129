import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { reduxBatch } from '@manaflair/redux-batch'
import userReducer from './slices/userSlice'
import apiReducer from './slices/apiSlice'

const reducers = combineReducers({
  user: userReducer,
  api: apiReducer,
})

const store = configureStore({
  reducer: reducers,
  enhancers: [reduxBatch],
})

export default store
