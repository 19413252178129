// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_modalContainer__oz9xd {\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(32, 31, 31, 0.6);\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 2; }\n\n.style_modal__Pm3cF {\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n", "",{"version":3,"sources":["webpack://./src/components/Modal/style.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,uCAAuC;EACvC,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,UAAU,EAAA;;AAGd;EACI,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA","sourcesContent":[".modalContainer{\n    position: fixed;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(32, 31, 31, 0.6);\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 2;\n}\n\n.modal{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "style_modalContainer__oz9xd",
	"modal": "style_modal__Pm3cF"
};
export default ___CSS_LOADER_EXPORT___;
