import GlossarySlider from '../GlossarySlider'
import SearchTerm from '../SearchTerm'
import style from '../style.module.scss'

const GlossaryHeader = ({ changeCurrKey, currentKeyIndex, isLetterGlossary, onChangeCurrStringSearch }) => {
  return (
    <div className={style.glossaryHeaderContainer}>
      <h1 className="toclink" data-link="glossary">
        Glossary
      </h1>
      <SearchTerm changeCurrKey={changeCurrKey} onChangeCurrStringSearch={onChangeCurrStringSearch} />
      <GlossarySlider
        changeCurrKey={changeCurrKey}
        currentKeyIndex={currentKeyIndex}
        isLetterGlossary={isLetterGlossary}
      />
    </div>
  )
}

export default GlossaryHeader
