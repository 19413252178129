const glossaryDict = {
  Agency: 'The crop insurance agency through which the crop insurance policy was purchased.',
  'Calculated Acres':
    'The total number of acres in a CLU, as calculated from the polygon which delineates it.',
  'Classified Acres': 'Acres that have been classified as containing a specific crop type.',
  CLU: 'Common Land Unit. A unit of land that has a permanent, contiguous boundary and common land management. Boundaries are delineated from relatively permanent features such as fence lines, roads, and/or waterways.',
  'Crop Acreage': 'The number of acres being operated in any given CLU.',
  'Crop Type':"The types of crops PlanetWatcher's Crop Identification (CI) service detects. Available options are corn, soybean, cotton, and wheat.",
  Confidence: "PlanetWatcher's confidence level in the correctness of a given prediction (expressed as a percentage).",
  'County Code': 'A three-digit FIPS code used to identify a particular county.',
  'Emerged Acreage': 'The number of acres inside a CLU on which crop emergence was detected.',
  Farm: 'A number identifying the farm.',
  Field: 'A number identifying the field associated with a tract and a farm.',
  'Flood Event': 'A weather event that led to water being detected inside a CLU.',
  Geometry: 'The spatial representation of a CLU depicted as a polygon',
  'Planted Acreage': 'The number of acres inside a CLU in which planting operation was detected.',
  'Planting Date': 'The date a crop was planted.',
  'Policy Number': 'The crop insurance policy number.',
  Raster: 'A picture/image.',
  'State Code': 'A two-digit FIPS code is used to identify a particular state.',
  Tract: 'A number representing a section of land as defined by the Risk Management Agency (RMA).',
  'Typical Growth': "Crop growth that matches previous years' growth pattern.",
  'Wind Event': 'A high-wind weather event that caused visible crop damage to be detected inside a CLU.',
}

export default glossaryDict
