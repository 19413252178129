import style from './style.module.scss'

const SectionWrapperGettingStarted = ({ children, linkName }) => {
  return (
    <div className={style.sectionWrapperGettingStarted} id={linkName}>
      {children}
    </div>
  )
}

export default SectionWrapperGettingStarted
