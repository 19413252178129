// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_codeSelectContainer__uNKZu {\n  position: absolute;\n  top: 0;\n  padding: 20px;\n  padding-top: 0;\n  left: 700px;\n  flex: 1 1; }\n  .style_codeSelectContainer__uNKZu h3 {\n    margin-bottom: 32px; }\n  .style_codeSelectContainer__uNKZu > div:first-child {\n    max-width: 100%; }\n\n@media (max-width: 1400px) {\n  .style_codeSelectContainer__uNKZu {\n    left: 500px; } }\n\n@media (max-width: 1300px) {\n  .style_codeSelectContainer__uNKZu {\n    position: initial;\n    padding-left: 0;\n    margin-top: 25px;\n    padding-right: 0;\n    left: 0; } }\n", "",{"version":3,"sources":["webpack://./src/components/ApiCalls/CodeSnippet/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,MAAM;EACN,aAAa;EACb,cAAc;EACd,WAAW;EACX,SAAO,EAAA;EANX;IAQQ,mBAAmB,EAAA;EAR3B;IAWQ,eAAe,EAAA;;AAIvB;EACI;IACI,WAAW,EAAA,EACd;;AAGL;EACI;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,OAAO,EAAA,EACV","sourcesContent":[".codeSelectContainer {\n    position: absolute;\n    top: 0;\n    padding: 20px;\n    padding-top: 0;\n    left: 700px;\n    flex: 1;\n    h3 {\n        margin-bottom: 32px;\n    }\n    > div:first-child {\n        max-width: 100%;\n    }\n}\n\n@media (max-width: 1400px) {\n    .codeSelectContainer {\n        left: 500px;\n    }\n}\n\n@media (max-width: 1300px) {\n    .codeSelectContainer {\n        position: initial;\n        padding-left: 0;\n        margin-top: 25px;\n        padding-right: 0;\n        left: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"codeSelectContainer": "style_codeSelectContainer__uNKZu"
};
export default ___CSS_LOADER_EXPORT___;
