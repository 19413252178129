import AcreageReportingListOfCalls from './AcreageReportingListOfCalls'
import style from './style.module.scss'
import ClaimsListOfCalls from './ClaimsListOfCalls'
import GetTokenListOfCalls from './GetTokenListOfCalls'
import SectionWrapper from '../SectionWrapper'
import HarvestDateListOfCalls from './HarvestDateListOfCalls'

const ApiCalls = () => {
  return (
    <SectionWrapper linkName={'api_calls'} styleBorder={{ borderBottom: 'none' }}>
      <div className={style.apiCallsContainer}>
        <h1 className={`${style.apiCallsHeader} toclink`} data-link="api_calls">
          API Calls
        </h1>
        <div className={style.apiToclinksContainer}>
          <h1 className={`${style.apiCallSep}`}>Authentication</h1>
          <GetTokenListOfCalls />
          <h1 className={style.apiCallSep}>Acreage Reporting</h1>
          <AcreageReportingListOfCalls />
          <h1 className={style.apiCallSep}>Claims</h1>
          <ClaimsListOfCalls />
          <h1 className={style.apiCallSep}>Field Operations</h1>
          <HarvestDateListOfCalls />
        </div>
      </div>
    </SectionWrapper>
  )
}

export default ApiCalls
