import style from '../style.module.scss'

const CodePreview = ({ title, activeCodePreview, setOtherLanguage }) => {
  return (
    <div
      className={`${style.codePreviewContainer} ${activeCodePreview === title ? style.active : ''}`}
      onClick={() => {
        setOtherLanguage(title)
      }}
    >
      {title}
    </div>
  )
}

export default CodePreview
