// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
// import virtualizedRenderer from 'react-syntax-highlighter-virtualized-renderer'
import prettier from 'prettier/standalone'
import parserBabel from 'prettier/parser-babel'

import style from './style.module.scss'
import CopyToClipboard from '../../../CopyToClipboard'
import { useMemo } from 'react'
import './Json.snippet.style.scss'
import syntaxHighlight from '../../utils/syntaxHighlight'

const ResponseDisplay = ({ response }) => {
  const responseParsed = useMemo(() => {
    return prettier.format(
      typeof response !== 'string' ? `${JSON.stringify(response, null, '\t')}` : response,
      {
        parser: 'json',
        plugins: [parserBabel],
      }
    )
  }, [response])

  if (!response) return ''
  return (
    <div className={style.responseDisplay}>
      {response && <CopyToClipboard response={response} />}
      {syntaxHighlight(responseParsed)}
    </div>
  )
}

export default ResponseDisplay
