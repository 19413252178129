import ResponseDisplay from '../CodeSnippet/ResponseDisplay'
import CodeResponseList from '../CodeSnippet/CodeResponseList'
import style from '../style.module.scss'
import { useMemo } from 'react'

const ResTypeCodeDisplay = ({ activeCodePreview, setActiveCodePreview, res, resTypeCodeRef, reqType }) => {
  const setOtherLanguage = (language) => {
    setActiveCodePreview(language)
  }

  return (
    <div className={style.resTypeContainerCodeDisplay} ref={resTypeCodeRef}>
      <h3>Response example</h3>
      <CodeResponseList
        activeCodePreview={activeCodePreview}
        reqType={reqType}
        setOtherLanguage={setOtherLanguage}
      />
      {useMemo(() => {
        if (!res) return ''
        return <ResponseDisplay response={res} type="json" />
      }, [res, activeCodePreview])}
    </div>
  )
}

export default ResTypeCodeDisplay
