import style from './style.module.scss'

const SectionWrapperApiCalls = ({ children, linkName }) => {
  return (
    <div className={style.sectionWrapperApiCalls} id={linkName}>
      {children}
    </div>
  )
}

export default SectionWrapperApiCalls
