// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_sectionWrapper__lHkUp {\n  padding-top: 25px;\n  padding-bottom: 25px;\n  border-bottom: 1px solid #cad3e2;\n  margin-left: 37px;\n  margin-right: 25px; }\n  .style_sectionWrapper__lHkUp:last-child {\n    border-bottom: none; }\n\n@media (min-width: 1299px) {\n  .style_sectionWrapper__lHkUp {\n    width: 458px; } }\n\n@media (min-width: 1400px) {\n  .style_sectionWrapper__lHkUp {\n    width: 660px; } }\n\n@media (max-width: 1300px) {\n  .style_sectionWrapper__lHkUp {\n    flex: 1 1;\n    width: calc(100vw - 472px);\n    padding-right: 0;\n    margin-right: 0; } }\n\n@media (max-width: 900px) {\n  .style_sectionWrapper__lHkUp {\n    flex: 1 1;\n    width: calc(100vw - 93px);\n    margin-right: 0; } }\n", "",{"version":3,"sources":["webpack://./src/components/SectionWrapper/style.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAiB;EACjB,oBAAoB;EACpB,gCAAgC;EAChC,iBAAiB;EACjB,kBAAkB,EAAA;EALtB;IAOQ,mBAAmB,EAAA;;AAI3B;EACI;IACI,YAAY,EAAA,EACf;;AAEL;EACI;IACI,YAAY,EAAA,EACf;;AAGL;EACI;IACI,SAAO;IACP,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe,EAAA,EAClB;;AAGL;EACI;IACI,SAAO;IACP,yBAAyB;IACzB,eAAe,EAAA,EAClB","sourcesContent":[".sectionWrapper {\n    padding-top: 25px;\n    padding-bottom: 25px;\n    border-bottom: 1px solid #cad3e2;\n    margin-left: 37px;\n    margin-right: 25px;\n    &:last-child {\n        border-bottom: none;\n    }\n}\n\n@media (min-width: 1299px) {\n    .sectionWrapper {\n        width: 458px;\n    }\n}\n@media (min-width: 1400px) {\n    .sectionWrapper {\n        width: 660px;\n    }\n}\n\n@media (max-width: 1300px) {\n    .sectionWrapper {\n        flex: 1;\n        width: calc(100vw - 472px);\n        padding-right: 0;\n        margin-right: 0;\n    }\n}\n\n@media (max-width: 900px) {\n    .sectionWrapper {\n        flex: 1;\n        width: calc(100vw - 93px);\n        margin-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionWrapper": "style_sectionWrapper__lHkUp"
};
export default ___CSS_LOADER_EXPORT___;
