import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import prettier from 'prettier/standalone'
import parserBabel from 'prettier/parser-babel'

import style from './style.module.scss'
import CopyToClipboard from '../../../CopyToClipboard'
import { useMemo } from 'react'
import apiRequestSample from '../../samples/apiRequestSample'

const CodeDisplay = ({ codeSample, activeCodePreview, filters, route }) => {
  const chosenCodePreview = useMemo(() => {
    if (activeCodePreview === 'javascript') {
      return prettier.format((codeSample || apiRequestSample)[activeCodePreview](filters, route), {
        parser: 'babel',
        plugins: [parserBabel],
      })
    }
    return (codeSample || apiRequestSample)[activeCodePreview](filters, route)
  }, [activeCodePreview, filters])

  return (
    <div className={style.codeDisplay}>
      <CopyToClipboard response={chosenCodePreview} />
      <SyntaxHighlighter language={activeCodePreview} showLineNumbers={true}>
        {chosenCodePreview}
      </SyntaxHighlighter>
    </div>
  )
}

export default CodeDisplay
