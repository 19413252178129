import style from '../style.module.scss'

const SearchTerm = ({ changeCurrKey, onChangeCurrStringSearch }) => {
  return (
    <div className={style.searchTermContainer}>
      <input
        type="text"
        placeholder="Search for an term"
        onChange={({ target }) => {
          onChangeCurrStringSearch(target.value)
        }}
      />
    </div>
  )
}

export default SearchTerm
