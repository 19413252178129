import HarvestReq from './HarvestReq'
import SectionWrapperApiCalls from '../SectionWrapperApiCalls'

import style from '../style.module.scss'

const HarvestDateListOfCalls = () => {
  return (
    <SectionWrapperApiCalls linkName={'harvest'}>
      <div className={style.getTokenContainer}>
        <h2 className="toclink" data-link="api_calls/harvest">
          ops/harvest_dates
        </h2>
        <p>Provides harvest date per field.</p>
        <br/>
        <p>
          In the case of multiple harvest operations within the same CLU, the latest harvest date is reported.
          In case no harvest operation is detected, a “Not-Detected” value will be assigned.
        </p>
        <HarvestReq />
      </div>
    </SectionWrapperApiCalls>
  )
}

export default HarvestDateListOfCalls
