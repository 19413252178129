import style from './style.module.scss'

const SectionWrapper = ({ children, linkName,classname, styleBorder }) => {
  return (
    <div className={`${style.sectionWrapper} ${classname}`} id={linkName} style={styleBorder}>
      {children}
    </div>
  )
}

export default SectionWrapper
