import { useRef, useState } from 'react'
import useClickOutside from '../../../hooks/useClickOutside'
import Select from '../Select'

const Asc = ({ filters, setFilters, classname }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const options = ['--', 'true', 'false']
  const ref = useRef(null)

  useClickOutside(ref, () => setIsSelectOpen(false))

  function handleSetFilters(e) {
    const { innerText } = e.target
    setIsSelectOpen(false)
    setFilters({
      ...filters,
      asc: innerText === '--' ? '' : innerText,
    })
  }

  const optionsCMP = options.map((item) => (
    <span
      role="button"
      onClick={handleSetFilters}
      key={item}
      type="button"
      style={filters?.asc === item ? { backgroundColor: '#F2F4F8' } : {}}
    >
      {item}
    </span>
  ))

  function handleOnSelectClick() {
    setIsSelectOpen(!isSelectOpen)
  }

  return (
    <div className={classname} ref={ref}>
      <Select onClick={handleOnSelectClick} value={filters.asc} isSelectOpen={isSelectOpen}>
        <option>{filters.asc}</option>
      </Select>
      {isSelectOpen && <div>{optionsCMP}</div>}
    </div>
  )
}

export default Asc
