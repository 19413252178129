// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_sessionExperation__ouRtO > div:first-child {\n  font-size: 0.875rem; }\n  .style_sessionExperation__ouRtO > div:first-child h2 {\n    font-family: \"NunitoSans-Bold\";\n    color: #415575; }\n  .style_sessionExperation__ouRtO > div:first-child h3 {\n    font-family: \"NunitoSans-Regular\";\n    color: #182f4e;\n    line-height: 150%;\n    margin-top: 12px; }\n", "",{"version":3,"sources":["webpack://./src/components/GettingStarted/SessionExpiration/style.module.scss"],"names":[],"mappings":"AAAA;EAEQ,mBAAmB,EAAA;EAF3B;IAIY,8BAA8B;IAC9B,cAAc,EAAA;EAL1B;IAQY,iCAAiC;IACjC,cAAc;IACd,iBAAiB;IACjB,gBAAgB,EAAA","sourcesContent":[".sessionExperation {\n    > div:first-child {\n        font-size: 0.875rem;\n        h2 {\n            font-family: \"NunitoSans-Bold\";\n            color: #415575;\n        }\n        h3 {\n            font-family: \"NunitoSans-Regular\";\n            color: #182f4e;\n            line-height: 150%;\n            margin-top: 12px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sessionExperation": "style_sessionExperation__ouRtO"
};
export default ___CSS_LOADER_EXPORT___;
