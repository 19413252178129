const resSampleHarvest = {
  404: `{
      "message":"Data not found.",
      "status": 404
      }`,
  401: `{
        "description": "Authorization header is expected",
        "message": "authorization_header_missing"
      }`,
  400: `
      {
        "message":"'limit' must be greater than 0.",
         "status" : 400}
        `,
  200: `[{"_id":"000043F8-7B73-418C-8258-88912D97EDF132AB","usda_clu_id":"000043F8-7B73-418C-8258-889D22123D97EDFAB","usda_farm_id":"3865","usda_tract_id":"2756","usda_field_id":"3","usda_state_code":"13","usda_county_code":"235"},{"_id":"00009398-26884-4F9D-AF20-9F6997D30B682","usda_clu_id":"000093982-6884-4F9D-AF20-9F6132997D30B68","usda_farm_id":"5874","usda_tract_id":"381","usda_field_id":"3","usda_state_code":"21","usda_county_code":"093"},{"_id":"0000d123fbe-5904-48a9-9b02-c9de729123dfa4de","usda_clu_id":"00012302dfbe-5904-48a9-9b02-c9de79dfa4de","usda_farm_id":"5664","usda_tract_id":"5418","usda_field_id":"4","usda_state_code":"27","usda_county_code":"151"},{"_id":"00012A0C-342A8-4021-BF0B-7645FB6DF7BB","usda_clu_id":"000212A0C-34A8-4021-BF0B-7645FB6DF7BB","usda_farm_id":"4920","usda_tract_id":"7416","usda_field_id":"4","usda_state_code":"123","usda_county_code":"028"},{"_id":"00021d2f2-f9c5-4ab3-b438-4a669e70a2581","usda_clu_id":"00021df2-f9c5-4ab3-b438-4a1236269e70a581","usda_farm_id":"5840","usda_tract_id":"4225","usda_field_id":"2","usda_state_code":"37","usda_county_code":"10"}]   
      `,
}
export default resSampleHarvest
