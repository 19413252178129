import style from './style.module.scss'

const ContactUs = ({ setIsShown }) => {
  return (
    <div className={style.contactUsContainer}>
      <div className={style.contactUsHeadlineContainer}>
        <h3>Need Assistance?</h3>{' '}
        <img
          src="/media/close.svg"
          onClick={() => {
            setIsShown(false)
          }}
          alt="close modal button"
        />
      </div>
      <h4>
        If you have any questions, please don't hesitate to email us at{' '}
        <a href="mailto:support@planetwatchers.com">support@planetwatchers.com</a> and we will respond within
        24 hours. If you would like us to call you back, please provide your phone number and a convenient
        time to reach you.
      </h4>
    </div>
  )
}

export default ContactUs
