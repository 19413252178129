import { useState } from 'react'
import style from '../style.module.scss'

const AboutDropDownPreview = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={style.aboutDropDownPreviewContainer}>
      <div className={style.aboutDropDownPreviewBtn} onClick={() => setIsOpen(!isOpen)}>
        <img src={`/media/${isOpen ? 'minus' : 'plus'}.svg`} alt="" />
        <h3>{title}</h3>
      </div>
      {isOpen && <div>{children}</div>}
    </div>
  )
}

export default AboutDropDownPreview
