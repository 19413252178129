// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_sectionWrapperGettingStarted__RTdV- {\n  padding-block: 25px;\n  border-bottom: 1px solid #cad3e2; }\n  .style_sectionWrapperGettingStarted__RTdV-:last-child {\n    padding-bottom: 0;\n    border-bottom: none; }\n", "",{"version":3,"sources":["webpack://./src/components/GettingStarted/SectionWrapperGettingStarted/style.module.scss"],"names":[],"mappings":"AAAA;EAKI,mBAAmB;EACnB,gCAAgC,EAAA;EANpC;IAEQ,iBAAiB;IACjB,mBAAmB,EAAA","sourcesContent":[".sectionWrapperGettingStarted {\n    &:last-child{\n        padding-bottom: 0;\n        border-bottom: none;\n    }\n    padding-block: 25px;\n    border-bottom: 1px solid #cad3e2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionWrapperGettingStarted": "style_sectionWrapperGettingStarted__RTdV-"
};
export default ___CSS_LOADER_EXPORT___;
