import { useNavigate } from 'react-router-dom'
import style from '../style.module.scss'

const ApliCallsListMenuItem = ({ list, linkName }) => {
  const navigate = useNavigate()

  const handleClick = (event, name) => {
    event.preventDefault()
    const anchorTarget = document.getElementById(name)
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
    navigate(`#${linkName}/${name}`)
  }

  return (
    <div className={style.apliCallsListMenuItem}>
      {list.map(({ name, title }, idx) => {
        return (
          <a href={`#${name}`} onClick={(ev) => handleClick(ev, name)} key={idx}>
            <h3>{title}</h3>
          </a>
        )
      })}
    </div>
  )
}

export default ApliCallsListMenuItem
