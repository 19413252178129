import style from './style.module.scss'

const Modal = ({ onOutSideClick, children }) => {
  return (
    <div onClick={onOutSideClick} className={`modal-container ${style.modalContainer}`}>
      <div className={style.modal}>{children}</div>
    </div>
  )
}

export default Modal
