const FilterValue = ({ filters, setFilters }) => {
  const onChange = ({ name, value }) => {
    setFilters({
      ...filters,
      [name]: value,
    })
  }

  return (
    <div>
      <input
        type="text"
        name="filter_value"
        placeholder="filter_value"
        value={filters.filter_value}
        onChange={({ target }) => onChange(target)}
      />
    </div>
  )
}

export default FilterValue
