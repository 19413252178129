import style from './style.module.scss'

const Toast = ({ toast }) => {
  if (!toast) return
  const { type, title, text } = toast
  return (
    <>
      <div className={style.toastContainer}>
        <div>
          <img src={`/media/${type}.svg`} alt="toast icon" />
        </div>
        <div>
          <h2>{title}</h2>
          <h3>{text}</h3>
        </div>
      </div>
    </>
  )
}

export default Toast
