const resSampleAcreageReporting = {
  404: `{
    "message":"Data not found.",
    "status": 404
    }`,
  401: `{
      "description": "Authorization header is expected",
      "message": "authorization_header_missing"
    }`,
  400: `
  {
    "message":"Bad request {message}",
     "status" : 400}
    `,
  200: `[
        {
          "_id": "00030009-73c4-4daa-9ed3-7066ac8125e5",
          "usda_clu_id" : "00030009-73c4-4daa-9ed3-7066ac8125e5",
          "usda_farm_id": "12",
          "usda_tract_id": "270",
          "usda_field_id": "30",
          "usda_state_code": "19",
          "usda_county_code": "011",
          "total_acres": 30,
          "policies": [
            "200054282"
          ],
          "agencies": [
            "*****"
          ],
          "planting_dates": {
            "planting_date": "2022-05-04T00:00:00Z",
            "last_updated": "2022-07-19T12:48:11.820Z",
            "confidence" : "medium"
          },
          "crop_identification": [
            {
              "crop_code": 81,
              "identified_acres": 0.5,
              "last_updated": "2022-08-15T00:00:00Z",
              "confidence" : "medium"
            }
          ]
        }]   
    `,
}

export default resSampleAcreageReporting
