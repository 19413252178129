import { useRef } from 'react'
import SliderLine from '../SliderLine'
import style from '../style.module.scss'

const GlossarySlider = ({ changeCurrKey, currentKeyIndex, isLetterGlossary }) => {
  const abc = 'abcdefghijklmnopqrstuvwxyz'.split('').map((c) => c.toUpperCase())
  const sliderRef = useRef()

  return (
    <div className={style.gloessarySliderContainer}>
      <div className={style.gloessarySliderLetters} ref={sliderRef}>
        {abc.map((letter, idx) => {
          const isLetterGloss = isLetterGlossary(letter)
          return (
            <div
              className={!isLetterGloss ? `${style.letterDisabled}` : ''}
              onClick={() => {
                if (isLetterGloss) changeCurrKey({ letter, idx })
              }}
              key={idx}
            >
              {letter.toUpperCase()}
            </div>
          )
        })}
      </div>
      <SliderLine currentKeyIndex={currentKeyIndex} sliderRef={sliderRef} />
    </div>
  )
}

export default GlossarySlider
