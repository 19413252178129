import WindReq from './WindReq'
import style from '../style.module.scss'
import SectionWrapperApiCalls from '../SectionWrapperApiCalls'

const ClaimsListOfCalls = () => {
  return (
    <SectionWrapperApiCalls linkName={'winds'}>
      <div className={style.getTokenContainer}>
        <h2 className="toclink" data-link="api_calls/winds">
          /claims/wind_damage
        </h2>
        <p>Provides various wind damage information.</p>
        <WindReq />
      </div>
    </SectionWrapperApiCalls>
  )
}

export default ClaimsListOfCalls
