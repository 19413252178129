// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_appShell__45yBY {\n  display: flex;\n  flex-direction: column;\n  height: 100vh; }\n\n.style_appBodyContainer__6yo9A {\n  position: relative;\n  flex: 1 1;\n  display: flex; }\n\n.style_currentPageContainer__0vAAE {\n  flex: 1 1;\n  display: flex; }\n\n.style_docSideBarOpenContainer__5YNsy {\n  position: absolute;\n  background-color: rgba(0, 0, 0, 0.6);\n  left: 0;\n  top: 0;\n  width: 100vw;\n  height: 100%;\n  z-index: 1;\n  opacity: 1;\n  animation: style_fadeIn__XYRK4 0.3s linear; }\n\n@keyframes style_fadeIn__XYRK4 {\n  from {\n    opacity: 0; }\n  to {\n    opacity: 1; } }\n", "",{"version":3,"sources":["webpack://./src/components/layout/AppShell/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,sBAAsB;EACtB,aAAa,EAAA;;AAGjB;EACI,kBAAkB;EAClB,SAAO;EACP,aAAa,EAAA;;AAGjB;EACI,SAAO;EACP,aAAa,EAAA;;AAGjB;EACI,kBAAkB;EAClB,oCAAsC;EACtC,OAAO;EACP,MAAM;EACN,YAAY;EACZ,YAAY;EACZ,UAAU;EACV,UAAU;EACV,0CAA6B,EAAA;;AAGjC;EACI;IACI,UAAU,EAAA;EAGd;IACI,UAAU,EAAA,EAAA","sourcesContent":[".appShell {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n}\n\n.appBodyContainer {\n    position: relative;\n    flex: 1;\n    display: flex;\n}\n\n.currentPageContainer {\n    flex: 1;\n    display: flex;\n}\n\n.docSideBarOpenContainer{\n    position: absolute;\n    background-color: rgba($color: #000000, $alpha: 0.6);\n    left: 0;\n    top: 0;\n    width: 100vw;\n    height: 100%;\n    z-index: 1;\n    opacity: 1;\n    animation: fadeIn 0.3s linear;\n}\n\n@keyframes fadeIn{\n    from{\n        opacity: 0;\n    }\n\n    to{\n        opacity: 1;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appShell": "style_appShell__45yBY",
	"appBodyContainer": "style_appBodyContainer__6yo9A",
	"currentPageContainer": "style_currentPageContainer__0vAAE",
	"docSideBarOpenContainer": "style_docSideBarOpenContainer__5YNsy",
	"fadeIn": "style_fadeIn__XYRK4"
};
export default ___CSS_LOADER_EXPORT___;
