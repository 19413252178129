// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/media/magnifying-glass-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_sideBarContentContainer__JVcIE {\n  background-color: #fff;\n  width: 340px;\n  height: 300px;\n  margin-left: 15px; }\n\n.style_searchIssueContainer__Mx9Zb {\n  width: 100%; }\n  .style_searchIssueContainer__Mx9Zb input {\n    width: 100%;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-position: left center;\n    background-position-x: 5px;\n    padding-block: 9px;\n    padding-left: 30px;\n    border: 1px solid #a5b5cc;\n    border-radius: 8px; }\n    .style_searchIssueContainer__Mx9Zb input:focus {\n      border: none; }\n", "",{"version":3,"sources":["webpack://./src/components/SideBar/SideBarContent/style.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,iBAAiB,EAAA;;AAGrB;EACI,WAAW,EAAA;EADf;IAGQ,WAAW;IACX,yDAA2E;IAC3E,4BAA4B;IAC5B,gCAAgC;IAChC,0BAA0B;IAC1B,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB,EAAA;IAX1B;MAaY,YAAY,EAAA","sourcesContent":[".sideBarContentContainer {\n    background-color: #fff;\n    width: 340px;\n    height: 300px;\n    margin-left: 15px;\n}\n\n.searchIssueContainer {\n    width: 100%;\n    input {\n        width: 100%;\n        background-image: url(\"../../../../public/media/magnifying-glass-icon.svg\");\n        background-repeat: no-repeat;\n        background-position: left center;\n        background-position-x: 5px;\n        padding-block: 9px;\n        padding-left: 30px;\n        border: 1px solid #a5b5cc;\n        border-radius: 8px;\n        &:focus{\n            border: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideBarContentContainer": "style_sideBarContentContainer__JVcIE",
	"searchIssueContainer": "style_searchIssueContainer__Mx9Zb"
};
export default ___CSS_LOADER_EXPORT___;
