import CodePreview from './CodePreview'
import style from './style.module.scss'

const CodePreviewList = ({ activeCodePreview, setOtherLanguage }) => {
  return (
    <div className={style.codePreviewListContainer}>
      <CodePreview title="python" activeCodePreview={activeCodePreview} setOtherLanguage={setOtherLanguage} />
      <CodePreview title="curl" activeCodePreview={activeCodePreview} setOtherLanguage={setOtherLanguage} />
      <CodePreview
        title="javascript"
        activeCodePreview={activeCodePreview}
        setOtherLanguage={setOtherLanguage}
      />
      <CodePreview title="cli" activeCodePreview={activeCodePreview} setOtherLanguage={setOtherLanguage} />
    </div>
  )
}

export default CodePreviewList
