import ApliCallsListMenuItem from '../ApliCallsListMenuItem'
import style from '../style.module.scss'

const acregaeReportings = [
  { name: 'acreage_reporting', title: '/acreage_reporting' },
  { name: 'acreage_reporting_acres', title: '/acreage_reporting/crop_acres' },
  { name: 'acreage_reporting_crop_types', title: '/acreage_reporting/crop_types' },
  {
    name: 'acreage_reporting_planting_dates',
    title: '/acreage_reporting/planting_dates',
  },
  {
    name: 'acreage_reporting_forecast',
    title: '/acreage_reporting/forecast',
  },
]
const authentications = [{ name: 'get_token', title: '/get_token' }]
const stormDamage = [{ name: 'winds', title: '/wind_damage' }]
const harvestDate = [{ name: 'harvest', title: '/harvest' }]
const ApiCallsMenuDropDown = ({ name }) => {
  return (
    <div className={style.apiCallsMenuDropDown}>
      <h2>Authentication</h2>
      <ApliCallsListMenuItem list={authentications} linkName={name} />
      <h2>Acreage Reporting</h2>
      <ApliCallsListMenuItem list={acregaeReportings} linkName={name} />
      <h2>Claims</h2>
      <ApliCallsListMenuItem list={stormDamage} linkName={name} />
      <h2>Field Operations</h2>
      <ApliCallsListMenuItem list={harvestDate} linkName={name} />
    </div>
  )
}

export default ApiCallsMenuDropDown
