import style from './style.module.scss'

const NoteDocumenation = ({handleNoteCloseBtnClick}) => {

    return (
        <div className={style.noteDocumenationContainer}>
            <div className={style.closeBtn} onClick={handleNoteCloseBtnClick}><img src="/media/close.svg" alt="close note"/></div>
            <p>
                Please note that we have recently changed the way we handle date strings in some of our API calls. This should not require any code changes, but please make sure that there are no issues on your end.
            </p>
        </div>
    )
}

export default NoteDocumenation