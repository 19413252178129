import style from '../../style.module.scss'

const VerticalSlider = ({ top }) => {
  return (
    <div className={style.verticalSliderContainer}>
      <div>
        <div style={{ top: `${top}px` }}></div>
      </div>
    </div>
  )
}

export default VerticalSlider
