import SectionWrapper from '../SectionWrapper'
import AboutDropDownList from './AboutDropDownList'
import style from './style.module.scss'

const About = () => {
  return (
    <>
      <SectionWrapper linkName={'about_plantwatchers'}>
        <div className={style.aboutParagraphContainer}>
          <h2 className="toclink" data-link="about_plantwatchers">
            About PlanetWatchers
          </h2>
          <p>
            At PlanetWatchers, we specialize in analyzing Synthetic-Aperture-Radar (SAR) satellite images at
            the country, state, county, and field levels. Using advanced machine learning and highly efficient
            algorithms, we are able to assess months' worth of field progress data in just minutes. This
            accurate analysis, available globally, has various applications in the crop insurance industry,
            such as supporting the insurance policy lifecycle and validating claims.
          </p>
        </div>
      </SectionWrapper>
      <SectionWrapper linkName={'about_the_api'}>
        <div className={style.aboutParagraphContainer}>
          <h2 className="toclink" data-link="about_the_api">
            About The API
          </h2>
          <p>
            The CropCycle API enables Approved Insurance Providers (AIPs) to integrate PlanetWatchers’
            analysis data into their software and applications, including third-party applications. The
            CropCycle API can be used to access analysis results produced by the PlanetWatchers platform and
            to integrate these results into third-party applications.
          </p>

          <p>All data is protected by an authentication system, which controls access to the data.</p>
        </div>
        <AboutDropDownList />
      </SectionWrapper>
    </>
  )
}

export default About
