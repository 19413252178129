import SectionWrapperGettingStarted from '../SectionWrapperGettingStarted'
import style from '../style.module.scss'

const StepTwo = () => {
  return (
    <SectionWrapperGettingStarted linkName={'step_two'}>
      <div className={style.stepTwoContainer}>
        <div className={style.headlineContainer}>
          <h3 className="toclink" data-link="getting_started/step_two">
            2. Generate an Access Token
          </h3>
          <h4>Using the API secret key, generate an "access_token" as follows:</h4>
        </div>
        <div className={style.viaCli}>
          <div>
            <h2>Via this interactive documentation</h2>
          </div>
          <div className={style.listInstructionsContainer}>
            <div className={style.instructionPreviewContainer}>
              <div>
                <h2>01</h2>
              </div>
              <div>
                <h3>
                  Navigate to the “API Calls” section of this document and click{' '}
                  <span className={`${style.textSpaceLeft}`}>/get_token </span> call{' '}
                </h3>
              </div>
            </div>
            <div className={style.instructionPreviewContainer}>
              <div>
                <h2>02</h2>
              </div>
              <div>
                <h3>Paste the secret key into the API-Key textbox.</h3>
              </div>
            </div>
            <div className={style.instructionPreviewContainer}>
              <div>
                <h2>03</h2>
              </div>
              <div>
                <h3>Optional: type in a session expiration value (in seconds).</h3>
              </div>
            </div>
            <div className={style.instructionPreviewContainer}>
              <div>
                <h2>04</h2>
              </div>
              <div>
                <h3>
                  Click <span>Execute.</span>
                </h3>
              </div>
            </div>
            <div className={style.instructionPreviewContainer}>
              <div>
                <h2>05</h2>
              </div>
              <div>
                <h3>
                  Select and copy the access token string from inside the quotation marks of the{' '}
                  <span>Response</span> body.
                </h3>
              </div>
            </div>
            <div className={style.instructionPreviewContainer}>
              <div>
                <h2>06</h2>
              </div>
              <div>
                <h3>Insert the access token after the word “Bearer” and a space. I.e. “Bearer &#123;access_token&#125;”</h3>
              </div>
            </div>
            <div className={style.instructionPreviewContainer}>
              <div>
                <h2>07</h2>
              </div>
              <div>
                <h3>Click the “Authorize” button on top of the document to open the panel and make sure the “Authorize” button has changed to a red “Logout” button.</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapperGettingStarted>
  )
}

export default StepTwo
