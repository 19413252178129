const resSampleAcreageReportingForecast = {
  404: `{
      "message":"Data not found.",
      "status": 404
      }`,
  401: `{
        "description": "Authorization header is expected",
        "message": "authorization_header_missing"
      }`,
  400: `
    {
      "message":"Bad request {message}",
       "status" : 400}
      `,
  200: `[
        {
            "_id":"3C98FAC4-26FE-4257-92F2-EC790E123428EE6",
            "usda_farm_id":"621626",
            "usda_tract_id":"136284",
            "usda_field_id":"126",
            "usda_state_code":"1347",
            "usda_county_code":"03115",
            "total_acres":719.28,
            "policies":[
               "211034055"
            ],
            "agencies":[
               "XXXXX"
            ],
            "forecast_year":"2023",
            "crop_forecast":[
               {
                  "crop_code":41,
                  "forecasted_acres":66.26,
                  "forecast_date":"2023-05-16T00:00:00Z"
               },
               {
                  "crop_code":81,
                  "forecasted_acres":1.35,
                  "forecast_date":"2023-05-16T00:00:00Z"
               }
            ]
         }]   
      `,
}

export default resSampleAcreageReportingForecast
