import { useEffect, useMemo, useState } from 'react'
import style from '../style.module.scss'

const MenuSubTitleItem = ({ activeItem, title, name, onclick }) => {
  const [anchorTarget, setAnchorTarget] = useState(null)

  const isOpen = useMemo(() => {
    return activeItem === title
  }, [activeItem])

  useEffect(() => {
    setAnchorTarget(document.getElementById(title))
  }, [name])

  const handleClick = (event) => {
    event.preventDefault()
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div
      className={`${style.menuSubTitleItemContainer} ${isOpen && style.activeMenuSubtitleItem}`}
      onClick={() => onclick(title)}
    >
      <a href={`#${title}`} onClick={handleClick}>
        <h2>{name}</h2>
      </a>
    </div>
  )
}

export default MenuSubTitleItem
