import './style/style.scss'
import { Provider } from 'react-redux'
import store from './store/store'
import AppShell from './components/layout/AppShell'
import { Helmet } from 'react-helmet'

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta
          name="description"
          content={process.env.REACT_APP_IS_SANDBOX ? 'CropCycle API sandbox' : 'CropCycle API documentation'}
        />
        <title>
          {process.env.REACT_APP_IS_SANDBOX ? 'CropCycle API sandbox' : 'CropCycle API documentation'}
        </title>
      </Helmet>
      <Provider store={store}>
        <AppShell />
      </Provider>
    </div>
  )
}

export default App
