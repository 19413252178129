const resSampleWind = {
    404: `{
        "message":"Data not found.",
        "status": 404
        }`,
    401: `{
          "description": "Authorization header is expected",
          "message": "authorization_header_missing"
        }`,
    400: `
        {
          "message":"'limit' must be greater than 0.",
           "status" : 400}
          `,
    200: `
    [
        {
          "usda_clu_id": "00030009-7123c4-4daa-9213-7066ac000000",
          "usda_farm_id": "1200",
          "usda_tract_id": "2065",
          "usda_field_id": "20",
          "usda_state_code": "17",
          "usda_county_code": "011",
          "total_acres": 19.35,
          "policies": [
            "2105421284"
          ],
          "agencies": [
            "my agency"
          ],
          "wind_damage": [
            {
              "event_date": { "$date": "2022-08-01T00:00:00.000Z" },
              "prediction_date": { "$date": "2022-11-16T00:00:00.000Z" },
              "total_damage_acres": 3.35,
              "no_damage_acres": 16.35,
              "low_damage_acres": 2,
              "medium_damage_acres": 1.35,
              "high_damage_acres": 0,
            },
          ]
        }
      ]  
        `,
}
export default resSampleWind
