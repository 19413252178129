import CodePreview from '../CodePreviewList/CodePreview'
import style from './style.module.scss'

const CodeResponseList = ({ activeCodePreview, reqType, setOtherLanguage }) => {
  return (
    <div className={style.codeResponseListContainer}>
      <CodePreview title="200" activeCodePreview={activeCodePreview} setOtherLanguage={setOtherLanguage} />
      <CodePreview title="400" activeCodePreview={activeCodePreview} setOtherLanguage={setOtherLanguage} />
      <CodePreview title="401" activeCodePreview={activeCodePreview} setOtherLanguage={setOtherLanguage} />
      {!reqType && (
        <CodePreview title="404" activeCodePreview={activeCodePreview} setOtherLanguage={setOtherLanguage} />
      )}
    </div>
  )
}

export default CodeResponseList
