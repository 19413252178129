import { useRef, useState } from 'react'
import useClickOutside from '../../../hooks/useClickOutside'
import Select from '../Select'

const DamageClu = ({ filters, setFilters, classname }) => {
    const [isSelectOpen, setIsSelectOpen] = useState(false)
    const options = ['--', 'No Damage', 'Wind Damage']
    const ref = useRef(null)

    useClickOutside(ref, () => setIsSelectOpen(false))

    function handleSetFilters(e) {
        const { innerText } = e.target
        setIsSelectOpen(false)
        setFilters({
            ...filters,
            wind_damage: innerText === '--' ? '' : innerText,
        })
    }

    const optionsCMP = options.map((item) => (
        <span
            role="button"
            onClick={handleSetFilters}
            key={item}
            type="button"
            style={filters?.wind_damage === item ? { backgroundColor: '#F2F4F8' } : {}}
        >
            {item}
        </span>
    ))

    function handleOnSelectClick() {
        setIsSelectOpen(!isSelectOpen)
    }

    return (
        <div className={classname} ref={ref}>
            <Select onClick={handleOnSelectClick} value={filters.wind_damage} isSelectOpen={isSelectOpen}>
                <option>{filters.wind_damage}</option>
            </Select>
            {isSelectOpen && <div>{optionsCMP}</div>}
        </div>
    )
}

export default DamageClu
