import axios from 'axios'

export async function checkSessionValidity(session_token) {
  try {
    await axios(`${process.env.REACT_APP_API_URL}/acreage_reporting`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${session_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    return true
  } catch (err) {
    return false
  }
}
