import { useState, useEffect } from 'react'
import GlossaryHeader from './GlossaryHeader'
import GlossaryListTerms from './GlossaryListTerms'

import glossaryDict from './glossaryDict'

import style from './style.module.scss'
import SectionWrapper from '../SectionWrapper'

const Glossary = () => {
  const [currKey, setCurrKey] = useState('A')
  const [currStringSearch, setCurrStringSearch] = useState('')
  const [listTerms, setListTerms] = useState(
    Object.keys(glossaryDict).filter((key) => key.toUpperCase().startsWith(currKey.toUpperCase()))
  )
  const [currentKeyIndex, setCurrKeyIndex] = useState(0)

  useEffect(() => {
    setListTerms(
      Object.keys(glossaryDict).filter((key) => key.toUpperCase().startsWith(currStringSearch.toUpperCase()))
    )
  }, [currStringSearch])

  const onChangeCurrKey = ({ letter, idx }) => {
    setCurrKey(letter.toUpperCase())
    setCurrKeyIndex(idx)
    setListTerms(
      Object.keys(glossaryDict).filter((key) => key.toUpperCase().startsWith(letter.toUpperCase()))
    )
  }

  const onChangeCurrStringSearch = (str) => {
    setCurrKeyIndex(str ? str.toLowerCase().charAt(0).charCodeAt(0) - 97 : 0)
    setCurrStringSearch(str || 'A')
  }

  const isLetterGlossary = (letter) => {
    return Object.keys(glossaryDict).filter((key) => key.startsWith(letter.toUpperCase())).length !== 0
  }

  return (
    <SectionWrapper linkName={'glossary'}>
      <div className={style.glossaryContainer}>
        <GlossaryHeader
          changeCurrKey={onChangeCurrKey}
          currentKeyIndex={currentKeyIndex}
          isLetterGlossary={isLetterGlossary}
          onChangeCurrStringSearch={onChangeCurrStringSearch}
        />
        <GlossaryListTerms currentKey={currKey} listTerms={listTerms} glossaryDict={glossaryDict} />
      </div>
    </SectionWrapper>
  )
}

export default Glossary
