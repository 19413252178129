import { useRef, useState } from 'react'
import useClickOutside from '../../../hooks/useClickOutside'
import Select from '../Select'

const SortBy = ({ filters, isWind, setFilters, classname, customOptions }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const options =
    customOptions ||
    (!isWind
      ? ['--', 'usda_clu_id', 'farm_tract_field', 'state_county', 'policies', 'agencies', 'planting_date']
      : ['--', 'usda_clu_id'])
  const ref = useRef(null)

  useClickOutside(ref, () => setIsSelectOpen(false))

  function handleSetFilters(e) {
    const { innerText } = e.target
    setIsSelectOpen(false)
    setFilters({
      ...filters,
      sort_by: innerText === '--' ? '' : innerText,
    })
  }

  const optionsCMP = options.map((item) => (
    <span
      role="button"
      onClick={handleSetFilters}
      key={item}
      type="button"
      style={filters?.sort_by === item ? { backgroundColor: '#F2F4F8' } : {}}
    >
      {item}
    </span>
  ))

  function handleOnSelectClick() {
    setIsSelectOpen(!isSelectOpen)
  }

  return (
    <div className={classname} ref={ref}>
      <Select onClick={handleOnSelectClick} value={filters.sort_by} isSelectOpen={isSelectOpen}>
        <option>{filters.sort_by}</option>
      </Select>
      {isSelectOpen && <div>{optionsCMP}</div>}
    </div>
  )
}

export default SortBy
