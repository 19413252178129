const Select = ({ title, onChange, children, classname, onClick, value, isSelectOpen }) => {
  function cancelDropDown(ev) {
    ev.preventDefault()
    ev.target.focus()
  }

  function handleOnChange(e) {
    const { value } = e.target
    onChange(value)
  }

  return (
    <div className={classname}>
      <select
        value={value}
        onMouseDown={cancelDropDown}
        onClick={onClick}
        style={{ boxShadow: isSelectOpen ? '0px 0px 0px 2px #4AE2D7' : '' }}
        onChange={handleOnChange}
        name="select"
      >
        {children}
      </select>
    </div>
  )
}

export default Select
