import SectionWrapper from '../SectionWrapper'
// import PlanetwatchersCli from './PlanetwatchersCli'
import StepTwo from './StepTwo'
import SessioExpiration from './SessionExpiration'
import StepOne from './StepOne'
import StepThree from './StepThree'
import style from './style.module.scss'
const GettingStarted = () => {
  return (
    <SectionWrapper linkName={'getting_started'}>
      <div className={style.gettingStartedContainer} id="getting_started">
        <div className={style.accessTokenContainer}>
          <div>
            <div>
              <h2 className="toclink" data-link="getting_started">
                Getting Started
              </h2>
              <h4>Perform the following steps to make your first API call:</h4>
            </div>
            <StepOne />
            <StepTwo />
            <SessioExpiration />
            <StepThree />
            {/* <PlanetwatchersCli /> */}
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default GettingStarted
