import { useState } from 'react'
import SearchLoader from '../layout/AppShell/SearchLoader'

import style from './style.module.scss'

const AuthorizeUser = ({ authorizeUser, authErr, isLoading }) => {
  const [authCrad, setAuthCrad] = useState('')
  const onChange = ({ target }) => {
    setAuthCrad(target.value)
  }

  return (
    <div className={style.authorizeModal}>
      <div className={style.authorizeModalContainer}>
        <div>
          <h1>Validate Access Token</h1>
        </div>
        <div>
          <h2>Paste or type in your "access_token"</h2>
          <input
            type="text"
            className={`${style.authInput} ${authErr ? style.authInputErr : ''}`}
            value={authCrad}
            onChange={onChange}
          />
          {authErr ? (
            <h4>
              {' '}
              <img src={`/media/${authErr.type}.svg`} alt="toast icon" />
              Token authorization failed
            </h4>
          ) : (
            ''
          )}
          {!isLoading ? (
            <button className={style.btnAuthorize} onClick={() => authorizeUser(authCrad)}>
              Authorize
            </button>
          ) : (
            <button className={`${style.loader} ${style.btnAuthorize}`}>
              <SearchLoader /> Loading
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default AuthorizeUser
