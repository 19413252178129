const Limit = ({ filters, setFilters }) => {
  const onChange = ({ name, value }) => {
    if (value > 1000) value = 1000
    if(value <= 0) value = 1
    setFilters({
      ...filters,
      [name]: value,
    })
  }

  return (
    <div>
      <input
        type="number"
        name="limit"
        placeholder="limit"
        value={filters.limit}
        onChange={({ target }) => onChange(target)}
      />
    </div>
  )
}

export default Limit
