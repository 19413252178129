import { useState, useEffect } from 'react'
import style from '../style.module.scss'

const SliderLine = ({ currentKeyIndex, sliderRef }) => {
  const [currFontWidth, setCurrWidth] = useState(16)
  useEffect(() => {
    if (!sliderRef.current) return
    window.addEventListener('resize', setFontWidthByWindowSize)
    return () => {
      window.removeEventListener('resize', setFontWidthByWindowSize)
    }
  }, [sliderRef])

  useEffect(() => {
    setFontWidthByWindowSize()
  }, [currentKeyIndex])

  useEffect(() => {
    setFontWidthByWindowSize()
  }, [])

  const setFontWidthByWindowSize = () => {
    const currentWindowSize = window.innerWidth
    const fontSize = currentWindowSize > 1400 ? 16 : 14
    const currSliderFullWidth = fontSize * 26
    const spaceBetweenLetters = (sliderRef?.current?.clientWidth - currSliderFullWidth) / 25
    setCurrWidth((spaceBetweenLetters + fontSize) * currentKeyIndex)
  }

  return (
    <div className={style.sliderLineContainer}>
      <div>
        <div style={{ left: `${currFontWidth}px` }}></div>
      </div>
    </div>
  )
}

export default SliderLine
