import { useMemo } from 'react'
import style from '../style.module.scss'

const MenuItem = ({ title, name, activeMenuItem, onClick }) => {
  const isOpen = useMemo(() => {
    return activeMenuItem === name
  }, [activeMenuItem])

  const handleClick = (event) => {
    event.preventDefault()
    const anchorTarget = document.getElementById(name)
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
    onClick(name)
  }
  return (
    <div className={`${style.menuItem} ${isOpen && style.activeDrop}`}>
      <a href={`#${name}`} onClick={handleClick}>
        <h2>{title}</h2>
      </a>
    </div>
  )
}

export default MenuItem
