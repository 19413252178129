import SectionWrapperGettingStarted from '../SectionWrapperGettingStarted'
import style from './style.module.scss'
const SessioExpiration = () => {
  return (
    <SectionWrapperGettingStarted linkName={'session_expiration'}>
      {' '}
      <div className={style.sessionExperation}>
        <div>
          <h2 className="toclink" data-link="getting_started/session_expiration">
            Session expiration time
          </h2>
          <h3>
            A session's expiration date is determined by the session_length parameter of the get_token call.
            If not stated, the session length is set to 24 hours.
          </h3>
        </div>
      </div>
    </SectionWrapperGettingStarted>
  )
}

export default SessioExpiration
