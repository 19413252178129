import GetTokenReq from './GetTokenReq'
import SectionWrapperApiCalls from '../SectionWrapperApiCalls'
import style from '../style.module.scss'

const GetTokenListOfCalls = () => {
  return (
    <SectionWrapperApiCalls linkName={'get_token'}>
      <div className={style.getTokenContainer}>
        <h2 className="toclink" data-link="api_calls/get_token">
          /get_token
        </h2>
        <p>
          Each API request must include an "access_token", which is generated using this call.
          Use the interactive panel on the right to generate a sandbox access token. Make sure to type “sandbox_api_key” in lieu of your production key in the Api-key box
        </p>

        <GetTokenReq />
      </div>
    </SectionWrapperApiCalls>
  )
}

export default GetTokenListOfCalls
