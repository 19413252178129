import SectionWrapperGettingStarted from '../SectionWrapperGettingStarted'
import style from '../style.module.scss'

const StepThree = () => {
  return (
    <SectionWrapperGettingStarted linkName={'step_three'}>
      <div className={style.stepThreeContainer}>
        <div className={`${style.headlineContainer} ${style.stepThreeCodeBlock}`}>
          <h3 className="toclink" data-link="getting_started/step_three">
            3. Make a Request
          </h3>
          <h4>
            Once you have received the access token, it is time to try your first request. Perform the
            following steps to make a request which returns the first five acreage report entries for your
            account, sorted by their ID numbers in ascending order:
          </h4>
        </div>
        <div className={`${style.viaSwaggerContainer} ${style.viaSwaggerStepThree}`}>
          <div>
            <h2>Via this interactive documentation</h2>
            <h3>This section assumes that you are already logged in.</h3>
          </div>
          <div className={style.listInstructionsContainer}>
            <div className={style.instructionPreviewContainer}>
              <div>
                <h2>01</h2>
              </div>
              <div>
                <h3>In the Acreage Reporting section of API calls, click</h3>
                <h3>
                  <span>/acreage_reporting </span>
                </h3>
              </div>
            </div>
            <div className={style.instructionPreviewContainer}>
              <div>
                <h2>02</h2>
              </div>
              <div>
                <h3>
                  Click <span className={`${style.textSpaceLeft}`}>Execute</span>{' '}
                </h3>
              </div>
            </div>
            <div className={style.instructionPreviewContainer}>
              <div>
               
                <h3>
                  Default values: (Put a horizontal line before and after this section. It should be a copy from the get_token section)
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapperGettingStarted>
  )
}

export default StepThree
