import { useRef, useState } from 'react'
import useClickOutside from '../../../hooks/useClickOutside'
import Select from '../Select'

const FilterBy = ({ setFilters, filters, classname, customOptions }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const options = customOptions || [
    '--',
    'usda_clu_id',
    'usda_tract_id',
    'usda_field_id',
    'usda_farm_id',
    'usda_state_code',
    'usda_county_code',
    'policies',
    'agencies',
    'planting_dates',
  ]
  const ref = useRef(null)

  useClickOutside(ref, () => setIsSelectOpen(false))

  function handleSetFilters(e) {
    const { innerText } = e.target
    setIsSelectOpen(false)
    setFilters({
      ...filters,
      filter_by: innerText === '--' ? '' : innerText,
    })
  }

  const optionsCMP = options.map((item) => (
    <span
      role="button"
      onClick={handleSetFilters}
      key={item}
      type="button"
      style={filters?.filter_by === item ? { backgroundColor: '#F2F4F8' } : {}}
    >
      {item}
    </span>
  ))

  function handleOnSelectClick() {
    setIsSelectOpen(!isSelectOpen)
  }

  return (
    <div className={classname} ref={ref}>
      <Select onClick={handleOnSelectClick} value={filters.filter_by} isSelectOpen={isSelectOpen}>
        <option>{filters.filter_by}</option>
      </Select>
      {isSelectOpen && <div>{optionsCMP}</div>}
    </div>
  )
}

export default FilterBy
