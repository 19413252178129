import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import calcMarginBotttomResponses from '../utils/calcMarginBotttomResponses'
const MARGIN_ERROR_CODE_CONTAINER = 0

const CalcMarginBottom = ({
  setParamsMarginBottom,
  response,
  resTypeRef,
  resTypeCodeRef,
  setResTypeCodeMarginBottom,
  setCodeConainerMarginBottom,
  codeRef,
  parametersReqRef,
}) => {
  const { screenSize } = useSelector((state) => state.user)
  const calcMarginBotttoms = () => {
    const calc = codeRef?.current?.clientHeight - parametersReqRef?.current?.clientHeight
    if (calc > 0) {
      setCodeConainerMarginBottom(0)
      setParamsMarginBottom(calc)
    } else if (calc < 0 && !response) {
      setParamsMarginBottom(0)
      setCodeConainerMarginBottom(-calc)
    }
    if (response) {
      setCodeConainerMarginBottom(0)
    }
    calcMarginBotttomResponses(
      resTypeRef.current.clientHeight,
      resTypeCodeRef.current.clientHeight,
      setResTypeCodeMarginBottom,
      screenSize.mode
    )
  }

  useEffect(() => {
    if (screenSize.mode !== 'Desktop') return
    calcMarginBotttoms()
  }, [screenSize])

  useEffect(() => {
    if (!response || screenSize.mode !== 'Desktop') return
    calcMarginBotttoms()
    calcMarginBotttomResponses(
      resTypeRef.current.clientHeight,
      resTypeCodeRef.current.clientHeight,
      setResTypeCodeMarginBottom,
      screenSize.mode
    )
  }, [response])
}

export default CalcMarginBottom
