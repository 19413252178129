import { cliParser, curlParser, pyJsParser } from '../utils/requestQueryParser'

const apiRequestSample = {
  curl: (filters, route) => {
    return `curl -X GET '${process.env.REACT_APP_API_URL}/${route}?${curlParser(filters)}' \
    --header 'accept: application/json' \
    --header 'Authorization: Bearer Api-Key '
    `
  },
  cli: (filters, route) => {
    return `plw-cli ${route}  ${cliParser(filters)}`
  },
  python: (filters, route) => {
    return `import requests

    url = "${process.env.REACT_APP_API_URL}/${route}?${pyJsParser(filters)}"

    payload={}
    headers = {
        'accept': 'application/json',
        'Authorization': 'Bearer Api-Key '
    }

    response = requests.request("GET", url, headers=headers, data=payload)
    print(response.content)
    `
  },
  javascript: (filters, route) => {
    return `fetch("${process.env.REACT_APP_API_URL}/${route}?${pyJsParser(filters)}", {
        method: 'GET',
        headers: {
            'accept': 'application/json',
            'Api-Key': 'Bearer Api-Key',
            'Content-Type': 'application/x-www-form-urlencoded'
          }
      });
    `
  },
}

export default apiRequestSample
