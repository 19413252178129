// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_noteDocumenationContainer__DW8bp {\n  position: relative;\n  padding-block: 20px;\n  padding-inline: 30px;\n  margin-top: 25px;\n  border-radius: 8px;\n  font-family: 'NunitoSans-Regular';\n  font-size: 0.875rem;\n  color: #FFFFFF;\n  line-height: 150%;\n  background-color: #00c6bb;\n  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05); }\n\n.style_closeBtn__83JMf {\n  position: absolute;\n  right: 0;\n  top: 0;\n  margin: 5px;\n  cursor: pointer; }\n", "",{"version":3,"sources":["webpack://./src/components/NoteDocumention/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,mBAAkB;EAClB,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;EAClB,iCAAiC;EACjC,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,yBAAyB;EACzB,+CAA+C,EAAA;;AAGnD;EACI,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,WAAW;EACX,eAAe,EAAA","sourcesContent":[".noteDocumenationContainer{\n    position: relative;\n    padding-block:20px;\n    padding-inline: 30px;\n    margin-top: 25px;\n    border-radius: 8px;\n    font-family: 'NunitoSans-Regular';\n    font-size: 0.875rem;\n    color: #FFFFFF;\n    line-height: 150%;\n    background-color: #00c6bb;\n    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);\n}\n\n.closeBtn{\n    position: absolute;\n    right: 0;\n    top: 0;\n    margin: 5px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noteDocumenationContainer": "style_noteDocumenationContainer__DW8bp",
	"closeBtn": "style_closeBtn__83JMf"
};
export default ___CSS_LOADER_EXPORT___;
