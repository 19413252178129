import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import MenuSubTitleItem from '../MenuSubTitleItem'
import style from '../style.module.scss'
import VerticalSlider from './VerticalSlider'

function getLink(location) {
  const locationHash = location.hash.substring(1)
  const names = locationHash.split('/')
  let name = ''
  if (names?.length > 1) name = names[1]
  return name
}

const gettingStarted = [
  {
    title: 'step_one',
    name: 'Request a Secret Key',
  },
  {
    title: 'step_two',
    name: `Generate an "access_token"`,
  },
  {
    title: 'session_expiration',
    name: 'Session expiration time',
  },
  {
    title: 'step_three',
    name: 'Make a Request',
  },
  {
    title: 'planetwatchers_cli',
    name: 'Using the PlanetWatchers CLI',
  },
]

const GettingStartedMenuDropDown = ({ name }) => {
  const [activeItem, setActiveItem] = useState('step_one')
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const linkName = getLink(location)
    setActiveItem(!linkName ? 'step_one' : linkName)
  }, [location])

  const activeIdx = useMemo(() => {
    return gettingStarted.findIndex((item) => {
      return item.title === activeItem
    })
  }, [activeItem])

  const onItemClick = (title) => {
    setActiveItem(title)
    navigate(`/#${name}/${title}`)
  }

  return (
    <div className={style.gettingStartedMenuDropDownContainer}>
      <VerticalSlider top={activeIdx * 44} activeIdx={activeIdx} />
      <div>
        {gettingStarted.map((item, idx) => {
          return (
            <MenuSubTitleItem
              onclick={onItemClick}
              activeItem={activeItem}
              title={item.title}
              name={item.name}
              key={idx}
            />
          )
        })}
      </div>
    </div>
  )
}

export default GettingStartedMenuDropDown
