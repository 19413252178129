const AUTH_SANDBOX_KEY = 'sandbox_api_key'

export async function authenticateSandbox(apiKey, sessionLength = '86400') {
  if (apiKey !== AUTH_SANDBOX_KEY) {
    return {
      description: 'Invalid api key',
      message: 'authorization failed, wrong api key',
    }
  }

  return {
    access_token: 'access_token_sandbox',
    session_length: sessionLength,
  }
}
