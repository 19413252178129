import AboutDropDownPreview from '../../About/AboutDropDownPreview'
import style from '../style.module.scss'
const GlossaryListTerms = ({ currentKey, listTerms, glossaryDict }) => {
  return (
    <div className={style.glossaryListConatainer}>
      {listTerms.map((key, idx) => {
        return (
          <AboutDropDownPreview title={key} key={idx}>
            <p>{glossaryDict[key]}</p>
          </AboutDropDownPreview>
        )
      })}
    </div>
  )
}

export default GlossaryListTerms
