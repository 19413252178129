import { useEffect, useState } from 'react'

import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'

import style from './style.module.scss'

const selectedCss = `
.my-selected{
  background-color: #00C6BB;
  color:#fff;
  cursor:pointer;
}
.rdp-button.my-selected:hover:not([aria-disabled='true']){
  background-color:#00C6BB;
}
.my-selected:active{
  background-color: rgb(74, 144, 226);
  color:#fff;
  border: none;
}
`
function dateIsValid(date) {
  return date instanceof Date && !isNaN(date)
}

function format(inputDate) {
  if (!dateIsValid(inputDate)) return
  let date, month, year

  date = inputDate.getDate()
  month = inputDate.getMonth() + 1
  year = inputDate.getFullYear()

  date = date.toString().padStart(2, '0')

  month = month.toString().padStart(2, '0')

  return `${date}-${month}-${year}`
}

const DatePicker = ({ filters, setFilters, type, currentOpenedDatePicker, setCurrentOpenedDatePicker }) => {
  const [selected, setSelected] = useState()
  const [isDayPickerVisible, setIsDayPickerVisible] = useState()

  useEffect(() => {
    const date = new Date(selected)
    setFilters({
      ...filters,
      [type]: format(date),
    })
    setIsDayPickerVisible(false)
  }, [selected])

  const onChange = (value) => {
    setFilters({
      ...filters,
      [type]: value,
    })
  }
  const openDatePicker = () => {
    const currentOpenedState = currentOpenedDatePicker !== type ? true : !isDayPickerVisible
    setCurrentOpenedDatePicker(type)
    setIsDayPickerVisible(currentOpenedState)
  }
  return (
    <>
      <style>{selectedCss}</style>
      <div className={style.windFilter}>
        <input
          type="text"
          placeholder={type}
          value={!filters[type] ? '' : filters[type]}
          onChange={({ target }) => onChange(target.value)}
          onClick={openDatePicker}
        />
        {isDayPickerVisible && currentOpenedDatePicker === type && (
          <div className={style.daypicker}>
            {' '}
            <DayPicker
              mode="single"
              selected={selected}
              onSelect={setSelected}
              modifiersClassNames={{
                selected: 'my-selected',
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default DatePicker
