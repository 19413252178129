const resSampleGetToken = {
  401: `{
      "description": "Authorization header is expected",
      "message": "authorization_header_missing"
    }`,
  200: `{
        "access_token": "{access_token}",
        "session_length": "86400"
      }   
    `,
}
export default resSampleGetToken
