import SectionWrapper from '../../SectionWrapper'
import style from './style.module.scss'
const Pagination = () => {
  return (
    <SectionWrapper linkName={'pagination'}>
      <div className={style.paginationContainer}>
        <h1 className="toclink" data-link="pagination">
          Pagination
        </h1>
        <h3>
          In case you want to split your responses (usually done for performance purposes) into several parts,
          we recommend using the <span>sort_by</span> parameter combined with the <span>start_from</span>{' '}
          parameter. The results will always be returned as a single list, but the order of items in the list
          may change unless you specify
          <span> the sort_by parameter</span>.
        </h3>
        <h2>Here are some examples of requests with pagination:</h2>
        <div className={style.paginationReqExample}>
          <div>
            <h3>https://api.crop-cycle.com/acreage_reporting?sort_by=usda_clu_id&limit=5</h3>
            <h4>- Returns the first five CLUs.</h4>
          </div>
          <div>
            <h3>
              https://api.crop-cycle.com/acreage_reporting?sort_by=usda_clu_id&start_from=10&limit=5&asc=false
            </h3>
            <h4>- Skips the first 10 CLUs and returns the next 5 CLUs.</h4>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default Pagination
