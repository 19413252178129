import ReqFliters from './ReqFilters'

const ApiRequestForm = (props, { children }) => {
  return (
    <div>
      {children}
      <ReqFliters {...props} />
    </div>
  )
}

export default ApiRequestForm
