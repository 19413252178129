import { useState } from 'react'
import style from '../../../style.module.scss'
import DatePicker from '../../../ApiRequestForm/ReqFilters/DatePicker'

const WindFilters = ({ filters, setFilters }) => {
  const [currentOpenedDatePicker, setCurrentOpenedDatePicker] = useState('')

  return (
    <div className={style.filtersForm}>
      <div>
        <h2>start_date</h2>
        <DatePicker
          filters={filters}
          currentOpenedDatePicker={currentOpenedDatePicker}
          setCurrentOpenedDatePicker={setCurrentOpenedDatePicker}
          setFilters={setFilters}
          type={'start_date'}
          classname={style.filterContainer}
        />
      </div>
      <div>
        <h2>end_date</h2>
        <DatePicker
          filters={filters}
          currentOpenedDatePicker={currentOpenedDatePicker}
          setCurrentOpenedDatePicker={setCurrentOpenedDatePicker}
          setFilters={setFilters}
          type={'end_date'}
          classname={style.filterContainer}
        />
      </div>
    </div>
  )
}

export default WindFilters
