import { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import CodeDisplay from './CodeDisplay'
import CodePreviewList from './CodePreviewList'
import ResponseDisplay from './ResponseDisplay'
import style from './style.module.scss'

function determineWidth(width) {
  if (width > 1400) {
    const maxWidth = width - 1150
    return maxWidth
  }
  const minWidth = width - 950
  return minWidth
}

const CodeSnippet = ({
  codeSample,
  route,
  classname,
  filters,
  title,
  response,
  children,
  codeRef,
  resCodeDisplay,
  marginBottom,
}) => {
  const { screenSize } = useSelector((state) => {
    return state.user
  })
  const [activeCodePreview, setActiveCodePreview] = useState('python')

  const styleByWidth = useMemo(() => {
    return screenSize?.mode === 'Desktop' ? { width: `${determineWidth(screenSize.size)}px` } : {}
  }, [screenSize])

  const styleByMarginBottom = useMemo(() => {
    return screenSize?.mode === 'Desktop' ? { marginBottom: `${marginBottom || 0}px` } : {}
  }, [screenSize, marginBottom])

  const setOtherLanguage = (language) => {
    setActiveCodePreview(language)
  }

  return (
    <div className={`${style.codeSelectContainer} ${classname}`} style={styleByWidth}>
      <div ref={codeRef} style={styleByMarginBottom}>
        {title && <h3>{title}</h3>}
        <CodePreviewList activeCodePreview={activeCodePreview} setOtherLanguage={setOtherLanguage} />
        <CodeDisplay
          codeSample={codeSample}
          route={route}
          activeCodePreview={activeCodePreview}
          filters={filters}
        />
        {children}
        {useMemo(() => {
          if (!response) return
          return <ResponseDisplay response={response} />
        }, [response])}
      </div>
      {resCodeDisplay && resCodeDisplay}
    </div>
  )
}

export default CodeSnippet
