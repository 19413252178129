import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import SideBarContent from './SideBarContent'

import style from './style.module.scss'

function SideBar() {
  const { screenSize, isOpenMenu } = useSelector((state) => state.user)
  const isSideBarOpen = useMemo(() => {
    if (!screenSize.mode === 'Phone') {
      return ''
    } else if (screenSize.mode === 'Phone' && !isOpenMenu) {
      return style.sideBarClosed
    }
    return style.sideBarOpen
  }, [isOpenMenu, screenSize])

  return (
    <>
      <nav className={`${style.sideBar} ${isSideBarOpen}`} aria-label="Sidebar">
        <SideBarContent />
      </nav>
    </>
  )
}

export default SideBar
