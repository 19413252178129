import { useMemo, useState } from 'react'
import { CopyToClipboard as CopyClipbaord } from 'react-copy-to-clipboard'
import style from './style.module.scss'

const CopyToClipboard = ({ message, response }) => {
  const [isCoppied, setIsCopied] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const responseCopy = useMemo(() => {
    if (typeof response === 'string') return response
    return response?.access_token ? response.access_token : JSON.stringify(response)
  }, [response])

  return (
    <div className={style.clipBoardContainer}>
      <CopyClipbaord
        text={responseCopy}
        onCopy={() => {
          setIsCopied(true)
          setTimeout(() => {
            setIsCopied(false)
          }, 3000)
        }}
      >
        <div
          className={style.clipBoardIcon}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <img src="/media/copy_clipboard.svg" alt="copy to clipboard" />
        </div>
      </CopyClipbaord>

      {isCoppied && <div className={style.clipBoardCopiedMessage}>Copied</div>}

      {isHover && <div className={style.clipBoardClickToCopyMessage}>Click to copy</div>}
    </div>
  )
}

export default CopyToClipboard
