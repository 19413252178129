// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_searchLoader__5ZPZv {\n  display: flex;\n  margin-right: 5px;\n  width: 15px !important;\n  animation: style_fadeIn__0JU1C 0.3s ease-in alternate; }\n\n.style_searchLoaderSpinner__TzxB5 {\n  width: 15px;\n  animation: style_searchLoaderSpin__dir6\\+ infinite 1s linear; }\n\n@keyframes style_fadeIn__0JU1C {\n  from {\n    opacity: 0; }\n  to {\n    opacity: 1; } }\n\n@keyframes style_searchLoaderSpin__dir6\\+ {\n  from {\n    transform: rotate(0deg); }\n  to {\n    transform: rotate(360deg); } }\n", "",{"version":3,"sources":["webpack://./src/components/layout/AppShell/SearchLoader/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,iBAAiB;EACjB,sBAAsB;EACtB,qDAAwC,EAAA;;AAG5C;EACI,WAAW;EACX,4DAA8C,EAAA;;AAGlD;EACI;IACI,UAAU,EAAA;EAEd;IACI,UAAU,EAAA,EAAA;;AAIlB;EACI;IACI,uBAAuB,EAAA;EAE3B;IACI,yBAAyB,EAAA,EAAA","sourcesContent":[".searchLoader {\n    display: flex;\n    margin-right: 5px;\n    width: 15px !important;\n    animation: fadeIn 0.3s ease-in alternate;\n}\n\n.searchLoaderSpinner {\n    width: 15px;\n    animation: searchLoaderSpin infinite 1s linear;\n}\n\n@keyframes fadeIn {\n    from {\n        opacity: 0;\n    }\n    to {\n        opacity: 1;\n    }\n}\n\n@keyframes searchLoaderSpin {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchLoader": "style_searchLoader__5ZPZv",
	"fadeIn": "style_fadeIn__0JU1C",
	"searchLoaderSpinner": "style_searchLoaderSpinner__TzxB5",
	"searchLoaderSpin": "style_searchLoaderSpin__dir6+"
};
export default ___CSS_LOADER_EXPORT___;
