import axios from 'axios'
import isEmpty from '../utils/isEmpty'

const cleanFilters = (filters) => {
  const { start_from } = filters
  let newFilters = { ...filters, start_from: start_from || 0 }
  newFilters = Object.fromEntries(Object.entries(newFilters).filter(([_, v]) => v !== ''))
  return newFilters
}

export async function harvest(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/harvest`, {
      params: { ...filters },
      headers,
    })
    if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch ({ response }) {
    const { data } = response
    return { message: data?.message, status: response?.status }
  }
}

export async function winds(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/wind_damage`, {
      params: { ...filters },
      headers,
    })
    if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch ({ response }) {
    const { data } = response
    return { message: data?.message, status: response?.status }
  }
}

export async function acreageReportingLastPlanting(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/acreage_reporting/planting_dates`, {
      params: { ...filters },
      headers,
    })
    if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch ({ response }) {
    const { data } = response
    return { message: data?.message, status: response?.status }
  }
}

export async function acreageReportingCropType(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/acreage_reporting/crop_types`, {
      params: { ...filters },
      headers,
    })
    if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch ({ response }) {
    const { data } = response
    return { message: data?.message, status: response?.status }
  }
}

export async function acreageReportingAcres(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/acreage_reporting/crop_acres`, {
      params: { ...filters },
      headers,
    })
    if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch ({ response }) {
    const { data } = response
    return { message: data?.message, status: response?.status }
  }
}

export async function acreageReportingForecast(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/acreage_reporting/forecast`, {
      params: { ...filters },
      headers,
    })
    if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch ({ response }) {
    const { data } = response
    return { message: data?.message, status: response?.status }
  }
}

export async function acreageReporting(filters, token) {
  filters = cleanFilters(filters)
  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/acreage_reporting`, {
      params: { ...filters },
      headers,
    })
    if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch ({ response }) {
    const { data } = response
    return { message: data?.message, status: response?.status }
  }
}
