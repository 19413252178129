import { useDispatch, useSelector } from 'react-redux'
import { setContactIsShown } from '../../../store/slices/userSlice'
import Modal from '../../Modal'
import ContactUs from './ContactUs'
import SideBarAccordion from './SideBarAccordion'
import style from './style.module.scss'

const SideBarContent = () => {
  const { contactIsShown } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const onOutSideClick = (ev) => {
    if (ev.target.classList.contains('modal-container')) {
      dispatch(setContactIsShown(false))
    }
  }

  const onSetIsShown = (state) => {
    dispatch(setContactIsShown(state))
  }

  return (
    <div className={style.sideBarContentContainer}>
      <SideBarAccordion />
      {contactIsShown && (
        <Modal onOutSideClick={onOutSideClick}>
          <ContactUs setIsShown={onSetIsShown} />
        </Modal>
      )}
    </div>
  )
}

export default SideBarContent
