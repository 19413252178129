import AboutDropDownPreview from '../AboutDropDownPreview'
import style from '../style.module.scss'

const AboutDropDownList = () => {
  return (
    <div className={style.aboutDropDownListContainer}>
      <AboutDropDownPreview title="Acreage Reporting">
        <div className={style.acreageReportingContainer}>
          <p>
            The acreage reporting data matches the data required by the U.S. Department of Agriculture (USDA),
            and consists of the following analysis outputs:
          </p>
          <div>
            <h4>Planting Dates</h4>
            <p>
              CropCycle Planting Dates (PD) service provides planting date detection for any CLU associated
              with the account. During planting operations, machinery causes visible changes to the soil
              surface, and PlanetWatchers uses a variety of data sources to detect these changes within the
              CLU boundaries. In cases where multiple planting operations are detected (i.e., on different
              dates) within the same CLU, the latest planting date is reported.
            </p>
            <h4>Crop Acres</h4>
            <p>
              CropCycle Crop Acres (CA) service detects the number of acres planted and operated for any CLU
              associated with the account. Planting machinery causes visible changes to the soil surface, and
              as the season progresses vegetation emergence provides additional information used for analyzing
              crop acres. PlanetWatchers uses a variety of data sources to detect these changes within a CLU’s
              boundary, creating a continuously updated crop acre figure for each CLU. In cases where multiple
              operations are detected (i.e., on different dates) within the same CLU, the acres reported is
              the sum of all acres with detected operations.
            </p>
            <h4>Crop Identification</h4>
            <p>
              PlanetWatchers’ Crop Identification (CI) service provides detection of the types of crops being
              grown (corn, soybean, cotton, and wheat) and their spatial location. Since many CLUs are planted
              with more than one crop type, PlanetWatchers’ CI service can report on up to three different
              crop types, providing identification, quantification, and mapping data for each crop.
            </p>
          </div>
        </div>
      </AboutDropDownPreview>
      <AboutDropDownPreview title="Claims Services">
        <div className={style.acreageReportingContainer}>
          <p>
            PlanetWatchers provides several Claims Services, each aimed at detecting specific field-level damage.
            Services are purchased and produced separately per customer, The services include:
          </p>
        <h4>Wind Damage</h4>
          <div className={style.acreageReportingContainer}>
            <p>
              CropCycle Storm Damage (WD) service provides detailed information to support insurance claims related to potential wind damage.
            </p>
            <p>
              The WD service assists in identifying damaged areas and levels of damage within CLU boundaries by
              comparing data captured before and after a storm event. Once post-event data is available,
              PlanetWatchers produces detailed maps of the damaged areas, which include information on the location
              and severity of the damages.
            </p>
        </div>
      <h4>Surface Water Detection</h4>
          <div className={style.acreageReportingContainer}>
            <p>
              CropCycle Surface Water Detection (SWD) service provides customers with the ability to identify all
              aboveground water located in any CLU.
            </p>
            <p>
              The ability of synthetic-aperture radar (SAR) to penetrate clouds, light rain, and other atmospheric events
              allows PlanetWatchers to produce accurate and timely water maps by continuously monitoring CLUs and
              waterways, even while precipitation events are still underway.
            </p>
          </div>
        </div>
      </AboutDropDownPreview>
    


      {/* <AboutDropDownPreview title="Surface Water Detection">
        <div className={style.acreageReportingContainer}>
          <p>
            CropCycle Surface Water Detection (SWD) service provides customers with the ability to identify
            all aboveground water located in any CLU.
          </p>
          <div>
            <p>
              The ability of synthetic-aperture radar (SAR) to penetrate clouds, light rain, and other
              atmospheric events allows PlanetWatchers to produce accurate and timely water maps by
              continuously monitoring CLUs and waterways, even while precipitation events are still underway.
            </p>
          </div>
        </div>
      </AboutDropDownPreview> */}
    </div>
  )
}

export default AboutDropDownList
